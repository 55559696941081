"use client";

import TextButton from "@/component-library/components/buttons/text-button/TextButton";
import { expandingTextStyle } from "@/component-library/components/layout/expanding-text/ExpandingText.css";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, {
    HTMLAttributes,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { animated, useSpring } from "react-spring";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface ExpandingTextProps extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    expandButtonLabel?: ReactNode;
    href?: string;
    target?: string;

    initialHeight?: number;

    buttonStyles?: React.CSSProperties;
}

const ExpandingText = ({
    children,
    expandButtonLabel = (
        <>
            Read more <FontAwesomeIcon icon={faAngleDown} />
        </>
    ),
    href,
    target,
    initialHeight = 60,
    buttonStyles,
    ...restProps
}: ExpandingTextProps) => {
    const contentHeightMeterRef = useRef<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [contentActualFullHeight, setContentActualFullHeight] =
        useState(initialHeight);

    const [contentAnimationStyles, contentAnimationApi] = useSpring(() => ({
        maxHeight: isOpen ? initialHeight : contentActualFullHeight,
        mask: isOpen
            ? `linear-gradient(180deg, black calc(100% - 24px), black 100%)`
            : `linear-gradient(180deg, black calc(100% - 24px), transparent 100%)`,
        config: {
            duration: Magnitudes.durationsInS.xs * 1000,
        },
    }));

    const animateHeightBasedOnIsOpen = useCallback(() => {
        void contentAnimationApi.start({
            to: {
                maxHeight: isOpen ? contentActualFullHeight : initialHeight,
                mask: isOpen
                    ? `linear-gradient(180deg, black calc(100% - 24px), black 100%)`
                    : `linear-gradient(180deg, black calc(100% - 24px), transparent 100%)`,
            },
            config: {
                duration: Magnitudes.durationsInS.xs * 1000,
            },
        });
    }, [contentActualFullHeight, contentAnimationApi, initialHeight, isOpen]);

    useIsomorphicLayoutEffect(() => {
        animateHeightBasedOnIsOpen();
    }, [animateHeightBasedOnIsOpen]);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (contentHeightMeterRef.current) {
                setContentActualFullHeight(
                    contentHeightMeterRef.current.clientHeight
                );
                animateHeightBasedOnIsOpen();
            }
        });
        if (contentHeightMeterRef.current) {
            observer.observe(contentHeightMeterRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [animateHeightBasedOnIsOpen]);

    return (
        <div {...restProps}>
            <animated.div
                className={clsx(expandingTextStyle)}
                style={stylex(contentAnimationStyles)}
            >
                <div ref={contentHeightMeterRef}>{children}</div>
            </animated.div>
            {href && !isOpen && (
                <a
                    href={href}
                    target={target}
                    // className={textButtonStyle}
                    style={stylex(
                        assignInlineVars({
                            minWidth: `0`,
                            padding: `${Magnitudes.spacingInRem.xxs}rem 0`,
                            textDecoration: `none`,
                            color: theme.colors.semantic.interactive.default,
                            fontSize: `${Magnitudes.fontInRem.m * 1.05}rem`,
                        }),
                        buttonStyles
                    )}
                >
                    {expandButtonLabel}
                </a>
            )}
            {!href && !isOpen && (
                <TextButton
                    onClick={() => {
                        setIsOpen((prevState) => !prevState);
                    }}
                    style={stylex(
                        assignInlineVars({
                            minWidth: `0`,

                            padding: `${Magnitudes.spacingInRem.xxs}rem 0`,
                        }),
                        buttonStyles
                    )}
                >
                    {expandButtonLabel}
                </TextButton>
            )}
        </div>
    );
};

export default ExpandingText;
