import { textButtonStyle } from "@/component-library/components/buttons/text-button/TextButtonStyles.css";
import { clsx } from "clsx";
import Link from "next/link";
import React, { ElementType, ReactNode, useMemo } from "react";

type AcceptedComponents = "button" | typeof Link | "a";

interface TextButtonProps<TAs extends AcceptedComponents>
    extends React.HTMLAttributes<TAs> {
    children?: ReactNode;
    as?: TAs;

    href?: string;
    target?: string;
}

const TextButton = <TAs extends AcceptedComponents>({
    children,
    as = "button" as TAs,
    className,
    ...restProps
}: TextButtonProps<TAs>) => {
    const As = useMemo(() => {
        return as as ElementType;
    }, [as]);

    return (
        <As className={clsx(textButtonStyle, className)} {...restProps}>
            {children}
        </As>
    );
};

export default TextButton;
